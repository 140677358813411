
  import Events from '@/events/Events';

  export default {
    props: {
      job: {
        type: Object,
        require: true,
        default: () => ({}),
      },
      boxType: {
        type: String,
        required: false,
        default: 'default',
      },
    },
    methods: {
      printJob() {
        window.print();
      },
      triggerTracking(channel) {
        this.$emitWithClientData(Events.JobsShared, {
          jobPostingId: this.job.id,
          channel: channel,
        });
      },
      openShareBox(refName, channel) {
        this.triggerTracking(channel);
        if (!this.$refs[refName]) return;
        const ref = this.$refs[refName];
        if (ref.value === null) return;
        clearInterval(ref.popupInterval);
        ref.popupWindow = undefined;
      },
      getShareUrl(network) {
        return (
          process.env.NUXT_ENV_BASE_URL +
          this.localePath({
            name: 'job-slug',
            params: {
              slug: this.job.slug,
            },
            query: {
              utm_source: 'pb_sharing',
              utm_medium: network,
            },
          })
        );
      },
    },
  };
