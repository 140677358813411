import { render, staticRenderFns } from "./JobBoxList.vue?vue&type=template&id=c6953318&"
import script from "./JobBoxList.vue?vue&type=script&lang=js&"
export * from "./JobBoxList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseJobBoxCard: require('/usr/src/app/components/base/JobBoxCard.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
installComponents(component, {VCard,VDivider,VList,VListItemGroup})
