
  import ScreenSize from '@/mixins/ScreenSize';
  export default {
    name: 'BaseJobBoxList',
    mixins: [ScreenSize],
    props: {
      jobs: {
        type: Array,
        required: true,
      },
      topJobPositions: {
        type: Array,
        default: () => [],
      },
      instantTeaserPositions: {
        type: Array,
        default: () => [],
      },
      buttonType: {
        type: String,
        required: false,
        default: 'dialogBtn',
      },
      headlineType: {
        type: String,
        required: false,
        default: 'h4',
      },
      jobBoxType: {
        type: String,
        required: false,
        default: 'Compact',
      },
      jobBoxWebhookType: {
        type: String,
        required: true,
      },
    },
  };
