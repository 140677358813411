import {
  calculateWorkPreferenceCompletenessForIncompleteAlert,
  calculateImageCompleteness,
  calculateAddressesCompleteness,
  calculateCommunicationDataCompleteness,
  calculatePromotionExperiencesCompletenessForIncompleteAlert,
  calculateActivitySkillsCompletenessForIncompleteAlert,
  calculateJobEmploymentCompletenessForIncompleteAlert,
} from '@/helper/ProfileCompletenessHelper';
import {checkIfObjectContainsKeyWithValue} from '@/helper/ObjectHelper';
export default {
  data() {
    return {
      hash: null,
      url: '',
    };
  },
  watch: {
    $route(to) {
      if (to.hash) {
        this.hash = to.hash;
      } else {
        this.hash = null;
      }
    },
    '$auth.user': async function () {
      this.setButtonUrl();
    },
  },
  mounted() {
    if (this.$route.hash) {
      this.hash = this.$route.hash;
    }
    this.setButtonUrl();
  },
  computed: {
    userProfileIncomplete() {
      return !(
        calculateAddressesCompleteness(this.$auth.user.candidateProfile) &&
        calculateImageCompleteness(this.$auth.user.candidateProfile) &&
        calculateCommunicationDataCompleteness(
          this.$auth.user.candidateProfile
        ) &&
        (checkIfObjectContainsKeyWithValue(
          this.$auth.user.candidateProfile.communicationData,
          'type',
          'communication_data_type.tel'
        ) ||
          checkIfObjectContainsKeyWithValue(
            this.$auth.user.candidateProfile.communicationData,
            'type',
            'communication_data_type.mobile'
          )) &&
        calculatePromotionExperiencesCompletenessForIncompleteAlert(
          this.$auth.user.candidateProfile
        ) &&
        calculateWorkPreferenceCompletenessForIncompleteAlert(
          this.$auth.user.candidateProfile
        ) &&
        calculateActivitySkillsCompletenessForIncompleteAlert(
          this.$auth.user.candidateProfile
        )
      );
    },
  },
  methods: {
    setButtonUrl() {
      let url = this.localePath({name: 'myData'});
      if (
        calculateAddressesCompleteness(this.$auth.user.candidateProfile) &&
        !calculateImageCompleteness(this.$auth.user.candidateProfile) &&
        calculateCommunicationDataCompleteness(
          this.$auth.user.candidateProfile
        ) &&
        (checkIfObjectContainsKeyWithValue(
          this.$auth.user.candidateProfile.communicationData,
          'type',
          'communication_data_type.tel'
        ) ||
          checkIfObjectContainsKeyWithValue(
            this.$auth.user.candidateProfile.communicationData,
            'type',
            'communication_data_type.mobile'
          )) &&
        calculatePromotionExperiencesCompletenessForIncompleteAlert(
          this.$auth.user.candidateProfile
        ) &&
        calculateWorkPreferenceCompletenessForIncompleteAlert(
          this.$auth.user.candidateProfile
        )
      ) {
        url = this.localePath({name: 'myImages'});
      } else {
        url = this.localePath({name: 'myData'});
        if (!this.$auth.user.candidateProfile.gender) {
          url = url + '#personal-data';
        } else if (
          !calculateAddressesCompleteness(this.$auth.user.candidateProfile)
        ) {
          url = url + '#addresses';
        } else if (
          !(
            calculateCommunicationDataCompleteness(
              this.$auth.user.candidateProfile
            ) &&
            (checkIfObjectContainsKeyWithValue(
              this.$auth.user.candidateProfile.communicationData,
              'type',
              'communication_data_type.tel'
            ) ||
              checkIfObjectContainsKeyWithValue(
                this.$auth.user.candidateProfile.communicationData,
                'type',
                'communication_data_type.mobile'
              ))
          )
        ) {
          url = url + '#communication-data';
        } else if (
          !calculateJobEmploymentCompletenessForIncompleteAlert(
            this.$auth.user.candidateProfile
          )
        ) {
          url = url + '#job-employment';
        } else if (
          !calculateActivitySkillsCompletenessForIncompleteAlert(
            this.$auth.user.candidateProfile
          )
        ) {
          url = url + '#activity-skills';
        } else if (
          !calculatePromotionExperiencesCompletenessForIncompleteAlert(
            this.$auth.user.candidateProfile
          )
        ) {
          url = url + '#promotion-experiences';
        } else if (
          !calculateWorkPreferenceCompletenessForIncompleteAlert(
            this.$auth.user.candidateProfile
          )
        ) {
          url = url + '#work-preference';
        }
      }
      this.url = url;
    },
  },
};
