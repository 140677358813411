
  import Events from '@/events/Events';
  import ScreenSize from '@/mixins/ScreenSize';
  export default {
    name: 'BaseJobBoxCard',
    components: {
      RegisterAndApplyButton: () =>
        import('@/components/RegisterAndApplyButton'),
      JobCardBadges: () => import('@/components/elements/JobCardBadges'),
    },
    mixins: [ScreenSize],
    props: {
      job: {
        type: Object,
        required: true,
      },
      topJobPositions: {
        type: Array,
        default: () => [],
      },
      instantTeaserPositions: {
        type: Array,
        default: () => [],
      },
      index: {
        type: Number,
        required: true,
      },
      buttonType: {
        type: String,
        required: false,
        default: 'dialogBtn',
      },
      headlineType: {
        type: String,
        required: false,
        default: 'h4',
      },
      jobBoxType: {
        type: String,
        required: false,
        default: 'Compact',
      },
      jobBoxWebhookType: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        blueBadge: false,
        badgeText: '',
      };
    },
    created() {
      if (this.job.job_type === 'job_type.top') {
        this.blueBadge = true;
        this.badgeText = 'filter.top_job';
      } else {
        this.blueBadge = false;
        if (this.job.is_highlight) {
          this.badgeText = 'filter.highlight';
        } else if (this.isPowerJob()) {
          this.badgeText = 'filter.power_job';
        }
      }
    },
    methods: {
      clickApplicationFromDetailsWebhook() {
        this.$emitWithClientData(Events.JobApplicationClickedFromDetails, {
          jobPostingId: this.job.id,
          type: this.jobBoxWebhookType,
        });
      },
      clickApplicationWebhook() {
        this.$emitWithClientData(Events.JobApplicationClicked, {
          jobPostingId: this.job.id,
          type: this.jobBoxWebhookType,
          position: this.index + 1,
          topJob: this.isTopTeaser(),
          instantTeaser:
            this.instantTeaserPositions &&
            this.instantTeaserPositions.includes(this.index + 1),
        });
      },
      clickWebhook(jobPostingId, position) {
        this.$emitWithClientData(Events.JobDetailsClicked, {
          jobPostingId,
          type: this.jobBoxWebhookType,
          position,
        });
      },
      isTopTeaser() {
        return (
          this.topJobPositions && this.topJobPositions.includes(this.index + 1)
        );
      },
      isPowerJob() {
        return (
          this.instantTeaserPositions &&
          (this.instantTeaserPositions.includes(this.index + 1) ||
            this.job.job_type === 'job_type.power')
        );
      },
    },
  };
