
  import NoDataFoundAlert from '@/components/NoDataFoundAlert';
  import Events from '@/events/Events';
  import {shuffleArray} from '@/helper/ArrayHelper';

  export default {
    components: {
      NoDataFoundAlert,
    },
    props: {
      jobPosting: {
        type: Object,
        required: true,
      },
      numJobPostings: {
        type: Number,
        required: false,
        default: 5,
      },
      title: {
        type: String,
        required: false,
        default: '',
      },
      boxType: {
        type: String,
        required: false,
        default: 'compact',
      },
      buttonType: {
        type: String,
        required: false,
        default: 'dialogBtn',
      },
    },
    data() {
      return {
        jobPostings: [],
        loading: true,
      };
    },
    mounted() {
      this.loadJobs().catch((e) => {
        this.jobPostings = [];
        this.loading = false;

        throw e;
      });
    },
    methods: {
      async loadJobs() {
        // Zusätzliche Abfrage und Berücksichtigung der Special-Jobs für die Boxen
        const specialOffers = await this.$cache.cache(
          'JobBoxSpecialOffers',
          () => {
            return this.$store.dispatch(
              'api/jobPosting/getJobPostingSpecialOffers'
            );
          },
          parseInt(process.env.NUXT_ENV_LOCALFORAGE_CACHE_TTL ?? 0)
        );

        const similarOffers =
          this.jobPosting && this.jobPosting.id
            ? await this.$store.dispatch(
                'api/jobPosting/getJobPostingSimilarOffers',
                {
                  id: this.jobPosting.id,
                  numberJobs: this.numJobPostings,
                }
              )
            : {data: [], totalItems: 0};

        let items = [];
        if (specialOffers && specialOffers.length > 0) {
          const specialOffersWithSimilarOffers = shuffleArray(specialOffers)
            .slice(0, Math.floor(this.numJobPostings / 2))
            .concat(similarOffers.data ? shuffleArray(similarOffers.data) : []);

          // Bereinigung um mögliche doppelte Einträge
          const jobPostingIds = [];
          for (const value of specialOffersWithSimilarOffers) {
            if (jobPostingIds.includes(value.id)) {
              return;
            }

            jobPostingIds.push(value.id);
            this.jobPostings.push(value);

            if (jobPostingIds.length >= this.numJobPostings) {
              break;
            }
          }
        } else {
          this.jobPostings = similarOffers.data
            ? shuffleArray(similarOffers.data)
            : [];
        }

        this.jobPostings = this.jobPostings.slice(0, this.numJobPostings);

        items = this.jobPostings.map((item, key) => ({
          id: item.id,
          position: key + 1,
        }));

        this.$emitWithClientData(Events.JobBoxViewed, {
          boxType: 'SimilarOffers',
          items,
        });

        this.loading = false;
      },
    },
  };
