import { render, staticRenderFns } from "./JobBoxSimilarOffers.vue?vue&type=template&id=098a585e&"
import script from "./JobBoxSimilarOffers.vue?vue&type=script&lang=js&"
export * from "./JobBoxSimilarOffers.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BaseJobBoxList: require('/usr/src/app/components/base/JobBoxList.vue').default})


/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VDivider,VSkeletonLoader})
