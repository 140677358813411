
  import ScreenSize from '@/mixins/ScreenSize';
  import {prepareImageUrl} from '@/helper/ImageHelper';
  import JobBoxSimilarOffers from '@/components/elements/JobBoxSimilarOffers';
  import ProfileIncomplete from '@/mixins/ProfileIncomplete';
  import LazyHydrate from 'vue-lazy-hydration';
  import ShareIcons from '@/components/ShareIcons.vue';

  export default {
    name: 'ThankYouPage',
    components: {
      ShareIcons,
      LazyHydrate,
      RichText: () => import('@/components/elements/RichText'),

      JobBoxSimilarOffers,
      ProfileIncompleteButton: () =>
        import('@/components/elements/ProfileIncompleteButton'),
    },
    mixins: [ProfileIncomplete, ScreenSize],
    props: {
      job: {
        type: Object,
        required: true,
      },
      position: {
        type: String,
        required: false,
        default: 'NULL',
      },
    },
    data() {
      return {
        section: {},
        tracking_script_included: false,
        imageUrl: '',
      };
    },
    async fetch() {
      this.section = await this.$cachedStrapi
        .find('application-success-dialog')
        .catch((e) => {
          if (e.statusCode !== 404) {
            throw e;
          }
        });
    },
    created() {
      if (this.section.image) {
        this.imageUrl = prepareImageUrl(this.section.image.url);
      }
    },
  };
