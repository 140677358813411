
  import AlertBox from '@/components/elements/AlertBox';

  export default {
    name: 'NoDataFoundAlert',
    components: {
      AlertBox,
    },
    props: {
      text: {
        type: String,
        required: false,
        default: '',
      },
      title: {
        type: String,
        required: false,
        default: '',
      },
    },
  };
