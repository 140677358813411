
  export default {
    props: {
      text: {
        type: String,
        required: false,
        default: '',
      },
      title: {
        type: String,
        required: false,
        default: '',
      },
      buttonText: {
        type: String,
        required: false,
        default: '',
      },
      type: {
        type: String,
        required: false,
        default: '',
      },
      prominent: {
        type: Boolean,
        required: false,
      },
      border: {
        type: String,
        required: false,
        default: '',
      },
      outlined: {
        type: Boolean,
        required: false,
      },
      tile: {
        type: Boolean,
        required: false,
      },
      icon: {
        type: String,
        required: false,
        default: '',
      },
      hash: {
        type: String,
        required: false,
        default: null,
      },
    },
    mounted() {
      if (this.hash) {
        this.scrollTo(document.location.hash);
      }
    },
    methods: {
      scrollTo(hash) {
        if (window.location.href.indexOf('meine-daten') > -1 && hash) {
          if (hash.length > 1) {
            const id = hash.substring(1);
            const element = document.getElementById(id);
            if (element) element.scrollIntoView({behavior: 'smooth'});
          }
        }
      },
    },
  };
